<template>
  <b-card-actions action-collapse ref="refreshCard" title="Daftar Admin Toko">
    <!-- <b-row> -->
    <!-- <b-col md="2" sm="4" class="my-1">
        <b-button v-if="allowCreate()" @click.prevent="add" variant="primary">
          <feather-icon icon="PlusIcon" class="mr-50" />
          {{ $t('Add') }}
        </b-button>
      </b-col> -->
    <!-- <b-col md="6" sm="12" class="my-1">
        <div class="d-flex justify-content-end">
          <b-button @click.prevent="exportMember" variant="outline-primary">Export</b-button>
        </div>
      </b-col> -->
    <!-- </b-row> -->
    <!-- <b-row>
    </b-row> -->
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group :label="$t('Per page')" label-size="sm" label-for="perPageSelect" class="mb-0">
          <!-- <label class="d-inline-block text-sm-left mr-50">Per page</label> -->
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"/>
        </b-form-group>
      </b-col>
      <!-- <b-col md="4" sm="8" class="my-1">
        <b-form-group :label="$t('Sort')" label-size="sm" label-for="sortBySelect"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col> -->
      <b-col md="6" class="my-1">
        <b-form-group :label="$t('Filter')" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Tekan enter untuk cari"
              @keydown.enter.prevent="getData()" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="
            filter = '';
          getData();
          ">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group label-size="sm" class="mb-0" label="Seller Status" label-for="sellerid">
          <!-- <label class="d-inline-block text-sm-left mr-50">Per page</label> -->
          <b-form-select id="sellerid" v-model="seller_sebagai" size="sm" :options="optseller"
            @change="getData()" />
        </b-form-group>
      </b-col>
      <!-- </b-row>
    <b-row> -->

      <b-col cols="12">
        <b-table striped hover small responsive :per-page="perPage" :items="items" :fields="fields"
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
          <template #cell(id)="row">
            <strong class="text-center">
              {{ row.index + 1 }}
            </strong>
          </template>

          <template #cell(foto)="{ item }">
            <img v-if="item.akun.karyawan.url_foto" style="width: 50px" :src="item.akun.karyawan.url_foto" alt="Foto" />
            <feather-icon v-else icon="UserIcon" size="32" />
          </template>
          <template #cell(jk)="data">
            <b-badge :variant="jk[1][data.value]">
              {{ jk[0][data.value] }}
            </b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge v-if="data.value == 0" variant="light-warning"> Pending </b-badge>
            <b-badge v-if="data.value == 1" variant="light-success"> Diterima </b-badge>
            <b-badge v-if="data.value == 2" variant="light-danger"> Ditolak </b-badge>
          </template>

          <template #cell(sebagai)="data">
            <b-badge variant="light-primary">
              {{ data.value == "marketing" ? "Marketing" : data.value == "cabang" ? "Cabang" : "-" }}
            </b-badge>
          </template>

          <!-- <template #cell(nama_lengkap)="row">
            <b-link @click="detail(row.item)">{{ row.item.nama_lengkap }}</b-link>
          </template> -->

          <template #cell(actions)="row">
            <b-button v-if="allowUpdate()" v-b-tooltip.hover.right="'Detail'" size="sm" @click="detail(row.item)"
              class="mr-1" variant="outline-primary">
              <feather-icon icon="ListIcon" />
            </b-button>
            <b-button v-if="allowUpdate() && row.item.sebagai == null" v-b-tooltip.hover.right="'Konfirmasi'" size="sm"
              @click="konfirmasi(row.item)" class="mr-1" variant="outline-primary">
              <feather-icon icon="FileIcon" />
            </b-button>
            <b-button v-if="allowUpdate() && row.item.sebagai == 'marketing'"
              v-b-tooltip.hover.right="'Edit Teritori Marketing'" size="sm" @click="editeritori(row.item)" class="mr-1"
              variant="outline-info">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button v-if="allowUpdate() && row.item.sebagai != null && (isFinance || isOwner)"
              v-b-tooltip.hover.right="'Atur Biaya'" size="sm" @click="setbiaya(row.item)" class="mr-1"
              variant="outline-info">
              <feather-icon icon="SettingsIcon" />
            </b-button>
            <b-button v-if="allowUpdate() && row.item.sebagai != null && isOwner|| isOwnerHeykama"
              v-b-tooltip.hover.right="'Atur Perizinan'" size="sm" @click="setizin(row.item)" class="mr-1"
              variant="outline-info">
              <feather-icon icon="LayersIcon" />
            </b-button>
            <!-- <b-button v-if="allowDelete()" v-b-tooltip.hover.right="'Hapus'" size="sm" @click="remove(row.item)"
              class="mr-1" variant="outline-danger">
              <feather-icon icon="TrashIcon" />
            </b-button> -->
          </template>
        </b-table>
      </b-col>
      <b-col cols="12">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
          class="my-0" />
      </b-col>
    </b-row>
    <b-modal no-close-on-esc no-close-on-backdrop v-model="showModal" id="modal-tambah"
      :size="form.sebagai != null ? 'xl' : 'md'" ok-variant="secondary" centered title="Form">
      <validation-observer ref="formMember">
        <b-row>
          <b-col cols="4">
            <b-form>
              <b-row>
                <div class="d-flex">
                  <feather-icon icon="UserIcon" size="19" />
                  <h6 class="mb-1 ml-50">Data Personal</h6>
                </div>
              </b-row>
              <b-row>
                <!-- NIK -->
                <b-col>
                  <b-form-group label="NIK" label-for="v-nik">
                    <validation-provider #default="{ errors }" name="nik" :rules="{ required: true, min: 2 }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.nik" id="v-nik"
                        placeholder="NIK" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Nama Lengkap -->
                <b-col>
                  <b-form-group label="Nama Lengkap" label-for="v-nama-lengkap">
                    <validation-provider #default="{ errors }" name="nama" :rules="{ required: true }">
                      <b-form-input v-model="form.nama_lengkap" :state="errors.length > 0 ? false : null"
                        id="v-nama-lengkap" placeholder="Nama Lengkap" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Foto -->
                <b-col>
                  <b-form-group label="Foto" label-for="v-file_foto">
                    <input type="file" ref="file_foto" @change="onSelectedImage" />
                    <div class="my-2" v-if="preview_image">
                      <img :src="preview_image" style="width: 100px" alt="" />
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Jenis Kelamin -->
                <b-col cols="6">
                  <b-form-group label="Jenis Kelamin" label-for="v-jk">
                    <validation-provider #default="{ errors }" name="jk" :rules="{ required: true }">
                      <b-form-select :state="errors.length > 0 ? false : null" v-model="form.jk" :options="optjk" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Agama -->
                <b-col cols="6">
                  <b-form-group label="Agama" label-for="v-agama">
                    <validation-provider #default="{ errors }" name="agama" :rules="{ required: true }">
                      <b-form-select :state="errors.length > 0 ? false : null" v-model="form.agama"
                        :options="optagama" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Tempat Lahir -->
                <b-col sm="12" md="6">
                  <b-form-group label="Tempat Lahir" label-for="v-tempat_lahir">
                    <validation-provider #default="{ errors }" name="tempat_lahir" :rules="{ required: true }">
                      <b-form-input v-model="form.tempat_lahir" :state="errors.length > 0 ? false : null"
                        id="v-tempat_lahir" placeholder="Tempat Lahir" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Tanggal Lahir -->
                <b-col sm="12" md="6">
                  <b-form-group label="Tanggal Lahir" label-for="v-tanggal_lahir">
                    <validation-provider #default="{ errors }" name="tanggal_lahir" :rules="{ required: true }">
                      <b-form-input type="date" v-model="form.tanggal_lahir"
                        :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Status Perkawinan -->
                <b-col>
                  <b-form-group label="Status Perkawinan" label-for="v-status_perkawinan">
                    <validation-provider #default="{ errors }" name="status_perkawinan" :rules="{ required: true }">
                      <b-form-select :state="errors.length > 0 ? false : null" v-model="form.status_perkawinan"
                        :options="optstatus_perkawinan" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Nomor HP -->
                <b-col>
                  <b-form-group label="Nomor HP" label-for="v-nomor-hp">
                    <validation-provider #default="{ errors }" name="no_hp" :rules="{ min: 10, required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.no_hp" id="v-nomor-hp"
                        placeholder="Nomor HP" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- email -->
                <b-col>
                  <b-form-group label="Email" label-for="v-email">
                    <validation-provider #default="{ errors }" name="email" :rules="{ required: true, email: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.email" id="v-email"
                        type="email" placeholder="Email" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Alamat -->
                <b-col>
                  <b-form-group label="Alamat" label-for="v-alamat">
                    <validation-provider #default="{ errors }" name="nip" :rules="{ required: true }">
                      <b-form-textarea :state="errors.length > 0 ? false : null" v-model="form.alamat" id="v-alamat"
                        placeholder="Alamat Lengkap" />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>

          <b-col cols="4">
            <b-form>
              <b-row>
                <div class="d-flex">
                  <feather-icon icon="BookOpenIcon" size="19" />
                  <h6 class="mb-1 ml-50">Data Pendidikan</h6>
                </div>
              </b-row>
              <b-row>
                <!-- Pendidikan Terakhir -->
                <b-col>
                  <b-form-group label="Pendidikan Terakhir" label-for="v-pendidikan_terakhir">
                    <validation-provider #default="{ errors }" name="pendidikan_terakhir" :rules="{ required: true }">
                      <b-form-input v-model="form.pendidikan_terakhir" :state="errors.length > 0 ? false : null"
                        id="v-pendidikan_terakhir" placeholder="Pendidikan Terakhir" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Kampus / Sekolah -->
                <b-col>
                  <b-form-group label="Kampus / Sekolah" label-for="v-kampus_sekolah">
                    <validation-provider #default="{ errors }" name="kampus_sekolah" :rules="{ required: true }">
                      <b-form-input v-model="form.kampus_sekolah" :state="errors.length > 0 ? false : null"
                        id="v-kampus_sekolah" placeholder="Kampus / Sekolah" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Kualifikasi Pendidikan -->
                <b-col>
                  <b-form-group label="Kualifikasi Pendidikan" label-for="v-kualifikasi_pendidikan">
                    <validation-provider #default="{ errors }" name="kualifikasi_pendidikan"
                      :rules="{ required: true }">
                      <b-form-input v-model="form.kualifikasi_pendidikan" :state="errors.length > 0 ? false : null"
                        id="v-kualifikasi_pendidikan" placeholder="Kualifikasi Pendidikan" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>

          <b-col cols="4">
            <b-form>
              <b-row>
                <div class="d-flex">
                  <feather-icon icon="BriefcaseIcon" size="19" />
                  <h6 class="mb-1 ml-50">Data Kepegawaian</h6>
                </div>
              </b-row>
              <b-row>
                <!-- NIP -->
                <b-col>
                  <b-form-group label="NIP" label-for="v-nip">
                    <validation-provider #default="{ errors }" name="nip" :rules="{ required: true, min: 2 }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.nip" id="v-nip"
                        placeholder="NIP" type="number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Jabatan -->
                <b-col>
                  <b-form-group label="Jabatan" label-for="v-jabatan">
                    <validation-provider #default="{ errors }" name="jabatan" :rules="{ required: true }">
                      <b-form-select :state="errors.length > 0 ? false : null" v-model="form.jabatan_id"
                        :options="optjabatan_id" label="Jabatan" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Status Member -->
                <b-col>
                  <b-form-group label="Status Member" label-for="v-status_Member">
                    <validation-provider #default="{ errors }" name="status_Member" :rules="{ required: true }">
                      <b-form-select :state="errors.length > 0 ? false : null" v-model="form.status_Member"
                        :options="optstatus_Member" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Status SP -->
                <b-col>
                  <b-form-group label="Status SP" label-for="v-status_sp">
                    <validation-provider #default="{ errors }" name="status_sp" :rules="{ required: true }">
                      <b-form-select :state="errors.length > 0 ? false : null" v-model="form.status_sp"
                        :options="optstatus_sp" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Masa Kerja -->
                <b-col>
                  <b-form-group label="Masa Kerja" label-for="v-masa_kerja">
                    <validation-provider #default="{ errors }" name="masa_kerja" :rules="{ required: true }">
                      <b-form-input v-model="form.masa_kerja" :state="errors.length > 0 ? false : null"
                        id="v-masa_kerja" placeholder="Masa Kerja" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Grade Member -->
                <b-col>
                  <b-form-group label="Grade Member" label-for="v-grade_Member">
                    <validation-provider #default="{ errors }" name="grade_Member" :rules="{ required: true }">
                      <b-form-input v-model="form.grade_Member" :state="errors.length > 0 ? false : null"
                        id="v-grade_Member" placeholder="Grade Member" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- submit and reset -->
              <b-col>
                <b-button type="submit" variant="primary" class="mr-1" @click.prevent="submit">
                  {{ label || "Submit" }}
                </b-button>
              </b-col>
            </b-form>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
    <b-modal no-close-on-esc no-close-on-backdrop v-model="modaldetail" id="modal-detail" size="xl"
      ok-variant="secondary" centered title="Detail dan Konfirmasi Member">
      <b-row>
        <!-- Informasi User -->
        <b-col :xl="form.sebagai != null ? 7 : 12" :lg="form.sebagai != null ? 7 : 12" md="12" sm="12"
          class="d-flex justify-content-between flex-column">
          <b-card border-variant="primary">
            <b-row>
              <b-col xl="12" lg="12" md="12" sm="12">
                <div class="d-flex justify-content-start">
                  <b-avatar :src="form.akun == null ? '-' : form.akun.karyawan ? form.akun.karyawan.url_foto : '-'"
                    :text="form.nama_lengkap" size="104px" rounded />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h4 class="mb-0">
                        {{ form.nama_lengkap }}
                      </h4>
                      <span class="card-text"><feather-icon icon="MailIcon" size="14" /> E-mail : {{ form.email
                        }}</span><br />
                      <span class="card-text"><feather-icon icon="KeyIcon" size="14" /> Seller ID : {{ form.sellerid ?
            form.sellerid : "-" }}</span> <br />
                      <span class="card-text"><feather-icon icon="MapPinIcon" size="14" /> Alamat : {{ form.alamat ?
            form.alamat : "-" }}, {{ form.kelurahan ? form.kelurahan.name : "-" }}, {{ form.kecamatan ?
            form.kecamatan.name : "-" }},
                        {{ form.kab_kota ? form.kab_kota.name : "-" }}, {{ form.provinsi ? form.provinsi.name : "-"
                        }}</span>
                    </div>
                    <div class="d-flex flex-wrap">
                      <!-- <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center mr-2">
                    <b-avatar variant="light-warning" rounded>
                      <feather-icon icon="KeyIcon" size="18" />
                    </b-avatar>
                    <div class="ml-1">
                      <small><feather-icon icon="KeyIcon" size="18" /> Seller ID</small>
                      <h5 class="mb-0">
                        {{ form.sellerid ? form.sellerid : '-' }}
                      </h5>
                    </div>
                  </div>
                </div> -->
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="12" lg="12" md="12" sm="12">
                <div class="d-flex align-items-center mt-1">
                  <div class="d-flex align-items-center mr-2">
                    <b-avatar variant="light-primary" rounded>
                      <feather-icon icon="ListIcon" size="18" />
                    </b-avatar>
                    <div class="ml-1">
                      <small>Seller Status</small>
                      <h5 class="mb-0">
                        <template v-if="form.sebagai == null"><b-button @click="konfirmasi2(form)"
                            variant="danger">Belum Konfirmasi</b-button></template>
                        <template v-else>{{ form.sebagai == "marketing" ? "Marketing" : "Cabang" }}</template>
                      </h5>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <b-avatar variant="light-info" rounded>
                      <feather-icon icon="UserIcon" size="18" />
                    </b-avatar>
                    <div class="ml-1">
                      <small>Jenis Kelamin</small>
                      <h5 class="mb-0">
                        {{ form.jk == 1 ? "Laki-laki" : "Perempuan" }}
                      </h5>
                    </div>
                  </div>
                  <!-- <div class="d-flex align-items-center">
                  <b-avatar variant="light-info" rounded>
                    <feather-icon icon="MapPinIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <small>Lokasi Cabang</small>
                    <h5 class="mb-0">
                      {{ form.wilayah  }}
                    </h5>
                  </div>
                </div> -->
                </div>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="CreditCardIcon" class="mr-75" />
                      <span class="font-weight-bold">KTP</span>
                    </th>
                    <td class="pb-50">
                      {{ form.card ? form.card : "-" }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="InstagramIcon" class="mr-75" />
                      <span class="font-weight-bold">Instagram</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ form.ig ? form.ig : "-" }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="SmartphoneIcon" class="mr-75" />
                      <span class="font-weight-bold">Whatsapp</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ form.wa ? form.wa : "-" }}
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
            <!-- <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              
                      <b-avatar variant="light-warning" rounded>
                  <feather-icon icon="KeyIcon" size="18" />
                </b-avatar>
                <div class="ml-1">
                  <small>Seller ID</small>
                  <h5 class="mb-0">
                    {{ form.sellerid ? form.sellerid : '-' }}
                  </h5>
                </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar variant="light-success" rounded>
                        <feather-icon icon="PhoneIcon" size="18" />
                      </b-avatar>
                      <div class="ml-1">
                        <small>Kontak</small>
                        <h5 class="mb-0">
                          {{ form.no_hp ? form.no_hp : '-' }}
                        </h5>
                      </div>
            </div>
          </div> -->
          </b-card>
        </b-col>
        <!-- Alamat -->
        <!-- <b-col xl="3" lg="3" md="12" sm="12">
          
        </b-col> -->
        <!-- </b-row> -->
        <!-- Alamat -->

        <!-- </b-row>
        <b-row>
        <h5 class="mb-0">
          Alamat
        </h5>
        <ul class="list-unstyled my-1">
          <li>
            <span class="align-middle">{{ form.alamat ? form.alamat : '-' }},</span>
          </li>
          <li class="my-25">
            <span class="align-middle">
              {{ form.kelurahan ? form.kelurahan.name : '-' }}, </span>
            <span class="align-middle">
              {{ form.kecamatan ? form.kecamatan.name : '-' }}, </span>
            <span class="align-middle">
              {{ form.kab_kota ? form.kab_kota.name : '-' }}, </span>
            <span class="align-middle">
              {{ form.provinsi ? form.provinsi.name : '-' }}, </span>
          </li>
        </ul>

      </b-col> -->
        <!-- <b-row> -->
        <b-col xl="5" lg="5" md="12" sm="12" v-if="form.sebagai != null">
          <b-card border-variant="primary">
            <!-- <hr /> -->
            <h5 class="mb-1">Lokasi Cabang</h5>
            <b-table-simple hover small caption-top responsive v-if="form.sebagai == 'marketing'">
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th>No</b-th>
                  <b-th>Teritori</b-th>
                  <b-th>Kota</b-th>
                  <b-th>Nama Cabang</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in form.wilayah" :key="index">
                  <b-td>{{ index + 1 }}</b-td>
                  <b-td>{{ item.nama_teritori }}</b-td>
                  <b-td>{{ item.nama_kota }}</b-td>
                  <b-td>{{ item.nama_cabang }}</b-td>
                </b-tr>
              </b-tbody>
              <b-tfoot>
                <b-tr>
                  <b-td colspan="7" variant="secondary" class="text-right">
                    Total Rows: <b>{{ form.wilayah.length }}</b>
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
            <b-table-simple hover small caption-top responsive v-if="form.sebagai == 'cabang'">
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th>No</b-th>
                  <b-th>Teritori</b-th>
                  <b-th>Kota</b-th>
                  <b-th>Nama Cabang</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in form.wilayah" :key="index">
                  <b-td>{{ index + 1 }}</b-td>
                  <b-td>{{ item.cabang.nama_teritori }}</b-td>
                  <b-td>{{ item.cabang.nama_kota }}</b-td>
                  <b-td>{{ item.cabang.nama_cabang }}</b-td>
                </b-tr>
              </b-tbody>
              <b-tfoot>
                <b-tr>
                  <b-td colspan="7" variant="secondary" class="text-right">
                    Total Rows: <b>{{ form.wilayah.length }}</b>
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
          </b-card>
        </b-col>
      </b-row>
      <!-- </b-card-body> -->
      <!-- </b-card>

        </b-col>
      </b-row> -->
      <template #modal-footer>
        <section class="d-flex justify-content-end align-items-center">
          <!-- <b-button size="sm" class="mr-1" variant="info" @click="submit">
                  Ubah
                </b-button> -->
          <!-- <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
                  Hapus isian
                </b-button> -->
          <b-button size="sm" variant="danger" @click="
            modaldetail = false;
          getData();
          ">
            Keluar
          </b-button>
        </section>
      </template>
    </b-modal>
    <b-modal no-close-on-esc no-close-on-backdrop v-model="modalkonfirmasi" id="modal-konfirmasi" size="md"
      ok-variant="secondary" centered title="Konfirmasi Member">
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12" v-if="form.sebagai == null">
          <b-card border-variant="primary">
            <!-- <hr /> -->
            <validation-observer ref="formKonfirmasi">
              <b-form>
                <b-row>
                  <!-- Status -->
                  <b-col xl="12" lg="12" md="12" sm="12">
                    <b-form-group label="Status Konfirmasi" label-for="v-status">
                      <validation-provider #default="{ errors }" name="status" :rules="{ required: true }">
                        <b-form-select :state="errors.length > 0 ? false : null" v-model="form.status"
                          :options="optstatus" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Sebagai -->
                  <b-col xl="12" lg="12" md="12" sm="12">
                    <b-form-group label="Seller Status" label-for="v-sebagai">
                      <validation-provider #default="{ errors }" name="sebagai" :rules="{ required: true }">
                        <b-form-select :state="errors.length > 0 ? false : null" v-model="form.seller_status"
                          :options="optsebagai" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Nama Cabang -->
                  <!-- <b-col xl="12" lg="12" md="12" sm="12" v-if="form.sebagai == 1">
                  <b-form-group label="Nama Cabang" label-for="v-nama_cabang">
                    <validation-provider #default="{ errors }" name="nama_cabang" :rules="{ required: true }">
                      <b-form-input v-model="form.nama_cabang" id="v-nama_cabang"
                        placeholder="Nama Cabang" />
                      <small class="text-danger">{{ errors[0] }}</small> :state="errors.length > 0 ? false : null"
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                  <b-col xl="12" lg="12" md="12" sm="12" v-if="form.status != 2">
                    <b-form-group label="Teritori" label-for="v-teritori">
                      <validation-provider #default="{ errors }" name="teritori" :rules="{ required: true }">
                        <v-select
                          v-if="form.seller_status == 1 || form.seller_status == null || form.seller_status == ''"
                          v-model="pilihteritori" :reduce="(option) => option.value" multiple label="text"
                          :options="optwilter" :state="errors.length > 0 ? false : null" />
                        <v-select v-if="form.seller_status == 2" v-model="pilihteritori"
                          :reduce="(option) => option.value" label="text" :options="optwilter"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Provinsi -->
                  <!-- <b-col xl="12" lg="12" md="12" sm="12">
                  <b-form-group label="Provinsi" label-for="v-provinsi">
                    <validation-provider #default="{ errors }" name="provinsi" :rules="{ required: true }">
                        <v-select
                        v-model="form.provinsi"
                      :reduce="(option) => option.value"
                      label="text"
                        :options="optprovinsi"
                         @input="getkabkota()"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col xl="12" lg="12" md="12" sm="12">
                  <b-form-group label="Kabupaten Kota" label-for="v-kab_kota">
                    <validation-provider #default="{ errors }" name="kab_kota" :rules="{ required: true }">
                      <v-select
                        v-model="form.kab_kota"
                      :reduce="(option) => option.value"
                        multiple
                      label="text"
                        :options="optkabko"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                </b-row>
              </b-form>
            </validation-observer>
            <b-button variant="primary" @click.prevent="submitkonfirmasi" block> Konfirmasi </b-button>
          </b-card>
        </b-col>
      </b-row>
      <template #modal-footer>
        <section class="d-flex justify-content-end align-items-center">
          <b-button size="sm" variant="danger" @click="
            modalkonfirmasi = false;
          getData();
          ">
            Keluar
          </b-button>
        </section>
      </template>
    </b-modal>
    <b-modal no-close-on-esc no-close-on-backdrop v-model="modaleditter" id="modal-editter" size="md"
      ok-variant="secondary" centered title="Edit Teritori">
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12">
          <b-card border-variant="primary">
            <!-- <hr /> -->
            <validation-observer ref="formEditKonfirmasi">
              <b-form>
                <b-row>
                  <!-- Status -->
                  <!-- <b-col xl="12" lg="12" md="12" sm="12">
                    <b-form-group label="Status Konfirmasi" label-for="v-status">
                      <validation-provider #default="{ errors }" name="status" :rules="{ required: true }">
                        <b-form-select :state="errors.length > 0 ? false : null" v-model="form.status"
                          :options="optstatus" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                  <!-- Sebagai -->
                  <!-- <b-col xl="12" lg="12" md="12" sm="12">
                    <b-form-group label="Seller Status" label-for="v-sebagai">
                      <validation-provider #default="{ errors }" name="sebagai" :rules="{ required: true }">
                        <b-form-select :state="errors.length > 0 ? false : null" v-model="form.seller_status" :options="optsebagai" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                  <!-- Nama Cabang -->
                  <!-- <b-col xl="12" lg="12" md="12" sm="12" v-if="form.sebagai == 1">
                  <b-form-group label="Nama Cabang" label-for="v-nama_cabang">
                    <validation-provider #default="{ errors }" name="nama_cabang" :rules="{ required: true }">
                      <b-form-input v-model="form.nama_cabang" id="v-nama_cabang"
                        placeholder="Nama Cabang" />
                      <small class="text-danger">{{ errors[0] }}</small> :state="errors.length > 0 ? false : null"
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                  <b-col xl="12" lg="12" md="12" sm="12" v-if="form.status != 2">
                    <b-form-group label="Teritori" label-for="v-teritori">
                      <validation-provider #default="{ errors }" name="teritori" :rules="{ required: true }">
                        <v-select v-if="form.sebagai == 'cabang' || form.sebagai == null || form.sebagai == ''"
                          v-model="pilihteritori" :reduce="(option) => option.value" multiple label="text"
                          :options="optwilter" :state="errors.length > 0 ? false : null" />
                        <v-select v-if="form.sebagai == 'marketing'" v-model="pilihteritori"
                          :reduce="(option) => option.value" label="text" :options="optwilter"
                          :state="errors.length > 0 ? false : null" />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
            <b-button variant="primary" @click.prevent="submiteditter" block> Edit Teritori </b-button>
            <b-button variant="danger" @click.prevent="hapuseditter" block> Lepas/Kosongkan Teritori </b-button>
          </b-card>
        </b-col>
      </b-row>
      <template #modal-footer>
        <section class="d-flex justify-content-end align-items-center">
          <b-button size="sm" variant="danger" @click="
            modaleditter = false;
          getData();
          ">
            Keluar
          </b-button>
        </section>
      </template>
    </b-modal>
    <b-modal no-close-on-esc no-close-on-backdrop v-model="modalbiaya" id="modal-biaya" size="lg" ok-variant="secondary"
      centered title="Atur Biaya per Member">
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12">
          <!-- <b-card border-variant="primary"> -->
          <!-- <hr /> -->
          <validation-observer ref="formbiaya">
            <b-form>
              <b-row>
                <b-col xl="4" lg="4" md="4" sm="12">
                  <b-form-group label="Biaya" label-for="v-teritori">
                    <validation-provider #default="{ errors }" name="teritori" :rules="{ required: true }">
                      <v-select v-model="formbiaya.code" :reduce="(option) => option.value" label="label" :options="[
            { value: 'biaya-layanan', label: 'Biaya Layanan' },
            { value: 'biaya-aplikasi', label: 'Biaya Aplikasi' },
          ]" :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col xl="3" lg="3" md="3" sm="12">
                  <b-form-group label="Tipe" label-for="v-teritori">
                    <validation-provider #default="{ errors }" name="teritori" :rules="{ required: true }">
                      <v-select v-model="formbiaya.type" :reduce="(option) => option.value" label="label" :options="[
            { value: 'nominal', label: 'Nominal' },
            { value: 'percentage', label: 'Percentage' },
          ]" :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col xl="3" lg="3" md="3" sm="12">
                  <b-form-group label="Nilai" label-for="v-value">
                    <validation-provider #default="{ errors }" name="value" :rules="{ required: true }">
                      <b-form-input v-model="formbiaya.value" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col xl="2" lg="2" md="2" sm="12">
                  <b-form-group label="-" label-for="v-value">
                  <b-button variant="primary" @click.prevent="submitbiaya" block> Simpan </b-button>
                </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <!-- {{itembiaya}} -->
          <b-table small :items="itembiaya" :fields="[
            { key: 'code', label: 'Kode' },
            { key: 'name', label: 'Nama Biaya' },
            { key: 'type', label: 'Tipe Biaya' },
            { key: 'value', label: 'Nilai Biaya' },
            ]">
          </b-table>
          <!-- <b-button variant="danger" @click.prevent="hapuseditter" block> Lepas/Kosongkan Teritori </b-button> -->
          <!-- </b-card> -->
        </b-col>
      </b-row>
      <template #modal-footer>
        <section class="d-flex justify-content-end align-items-center">
          <b-button size="sm" variant="danger" @click="
              modalbiaya = false;
              getData();
            ">
            Keluar
          </b-button>
        </section>
      </template>
    </b-modal>
    <b-modal no-close-on-esc no-close-on-backdrop v-model="modalizin" id="modal-izin" size="lg" ok-variant="secondary"
      centered title="Atur Perizinan per Member">
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12">
          <!-- <b-card border-variant="primary"> -->
          <!-- <hr /> -->
          <validation-observer ref="formizin">
            <b-form>
              <b-row>
                <b-card title="MENUNGGU API"></b-card>
              </b-row>
            </b-form>
          </validation-observer>
          <!-- {{itembiaya}} -->
          <!-- <b-table small :items="itemizin" :fields="[
            { key: 'code', label: 'Kode' },
            { key: 'name', label: 'Nama Biaya' },
            { key: 'type', label: 'Tipe Biaya' },
            { key: 'value', label: 'Nilai Biaya' },
            ]">
          </b-table> -->
          <!-- <b-button variant="danger" @click.prevent="hapuseditter" block> Lepas/Kosongkan Teritori </b-button> -->
          <!-- </b-card> -->
        </b-col>
      </b-row>
      <template #modal-footer>
        <section class="d-flex justify-content-end align-items-center">
          <b-button size="sm" variant="danger" @click="
              modalizin = false;
              getData();
            ">
            Keluar
          </b-button>
        </section>
      </template>
    </b-modal>
  </b-card-actions>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import {
  BLink,
  BTable,
  BFormFile,
  VBTooltip,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BCard,
  BCardText,
  BCardHeader,
  BCardBody,
  BFormDatepicker,
  BTableSimple,
  BThead,
  BTfoot,
  BTbody,
  BTh,
  BTr,
  BTd,
} from "bootstrap-vue";
import service from "@/services";
export default {
  components: {
    BTableSimple,
    BThead,
    BTfoot,
    BTbody,
    BTh,
    BTr,
    BTd,
    vSelect,
    BLink,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    BCard,
    VBTooltip,
    BModal,
    BFormFile,
    BFormDatepicker,
    BTable,
    BAvatar,
    BCardHeader,
    BCardBody,
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BCardText,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      modalbiaya: false,
      itembiaya: [],
      required,
      email,
      confirmed,
      password,
      showModal: false,
      label: "Submit",
      form: {
        file_foto: null,
        nama_lengkap: null,
        no_hp: null,
        email: null,
        nik: null,
        nip: null,
        alamat: null,
        jabatan_id: null,
        jk: null, //Di isi dengan 1 = Laki-Laki OR 2 = Perempuan
        agama: null, //Di isi dengan 1 = ISLAM OR 2 = KRISTEN OR 3 = KATOLIK OR 4 = HINDU OR 5 = BUDDHA OR 6 = KHONGHUCU
        status_perkawinan: null, // Di isi dengan 1 = Lajang OR 2 = Menikah OR 3 = Duda/Janda
        status_Member: null, //Di isi dengan 1 = Aktif Or 0 = Tidak Aktif
        status_sp: null, // Di isi dengan 0 = Tidak Mempunyai Sp Or 1 = Sp 1 Or 2 = Sp 2 Or 3 = Sp 3
        kampus_sekolah: "",
        pendidikan_terakhir: "",
        kualifikasi_pendidikan: "",
        masa_kerja: "",
        grade_Member: "",
        file_foto: "",
        tempat_lahir: "",
        tanggal_lahir: "", //Format 2022-01-22
      },
      modalkonfirmasi: false,
      modaleditter: false,
      optjabatan_id: [],
      seller_sebagai: null,
      optseller: [
        { value: null, text: "-Semua-" },
        { value: 'pending', text: "Belum Konfirmasi" },
        { value: 'marketing', text: "Marketing" },
        { value: 'cabang', text: "Cabang" },
      ],
      optjk: [
        { value: 1, text: "Laki-laki" },
        { value: 2, text: "Perempuan" },
      ],
      optagama: [
        { value: 1, text: "Islam" },
        { value: 2, text: "Kristen" },
        { value: 3, text: "Katolik" },
        { value: 4, text: "Hindu" },
        { value: 5, text: "Buddha" },
        { value: 6, text: "Khonghucu" },
      ],
      optstatus_perkawinan: [
        { value: 1, text: "Lajang" },
        { value: 2, text: "Menikah" },
        { value: 3, text: "Duda/Janda" },
      ],
      optstatus_Member: [
        { value: 1, text: "Aktif" },
        { value: 2, text: "Tidak Aktif" },
      ],
      optstatus_sp: [
        { value: 0, text: "Tidak mempunyai SP" },
        { value: 1, text: "SP 1" },
        { value: 2, text: "SP 2" },
        { value: 3, text: "SP 3" },
      ],
      value: "",
      formatted: "",
      selected: "",
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      search: null,
      fields: [
        {
          key: "id",
          label: "No",
        },
        // { key: "foto", label: "Foto" },
        { key: "nama_lengkap", label: "Nama", sortable: true },
        // { key: "jk", label: "L/P", sortable: true },
        // { key: "akun.level.nama_level", label: "Level", sortable: true },
        { key: "sebagai", label: "Seller Status", sortable: true },
        { key: "status", label: "Status", sortable: true },
        // { key: "akun.karyawan.alamat", label: "Alamat", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      jk: [
        {
          1: "Laki-laki",
          2: "Perempuan",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      agama: [
        {
          1: "Islam",
          2: "Kristen",
          3: "Katolik",
          4: "Hindu",
          5: "Buddha",
          6: "Khonghucu",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      status_perkawinan: [
        {
          1: "Lajang",
          2: "Menikah",
          3: "Duda/Janda",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      status_Member: [
        {
          1: "Aktif",
          2: "Tidak Aktif",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      status_sp: [
        {
          0: "Tidak mempunyai SP",
          1: "SP 1",
          2: "SP 2",
          3: "SP 3",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
      formbiaya: {
        code: null,
        type: null,
        value: null,
      },
      preview_image: null,
      modaldetail: false,
      optstatus: [
        // { value: 0, text: "Pending" },
        { value: 1, text: "Terima" },
        { value: 2, text: "Tolak" },
      ],
      optsebagai: [
        { value: 1, text: "Cabang" },
        { value: 2, text: "Marketing" },
      ],
      optprovinsi: [],
      optkabko: [],
      optwilter: [],
      pilihteritori: [],
      formizin: {

      },
      modalizin: false,
      itemizin: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.getData();
    this.getProvinsi();
    this.getWilter();
    // this.getDataJabatan();
  },

  watch: {
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    // filter(keyword) {
    //  if (keyword.length > 2 || keyword.length == 0) {
    //    this.getData();
    // }
    //},
    currentPage(val) {
      if (val) {
        this.getData();
        // this.items
      }
    },
  },
  methods: {
    async submitbiaya() {
      this.$refs.formbiaya.validate().then((success) => {
        if (success) {
          let payload = {
            member_id: this.formbiaya.id,
            code: this.formbiaya.code,
            type: this.formbiaya.type,
            value: this.formbiaya.value
          }
          this.$store
            .dispatch("setbiayamember/save", [payload])
            .then(() => {
              // this.modalbiaya = false;
              this.displaySuccess({
                text: "Berhasil disimpan",
              });
              this.getDataSetBiaya(this.formbiaya);
            })
            .catch((e) => console.error(e));
        }}
      )
      },
        async submitkonfirmasi() {
        this.$refs.formKonfirmasi.validate().then((success) => {
          if (success) {
            this.label = "Loading...";
            if (this.pilihteritori.length > 1) {
              let join = this.pilihteritori;
              let string = join.join(",");
              var array = JSON.parse("[" + string + "]");
              let payload = {
                member_id: this.form.id,
                // nama_cabang: this.form.nama_cabang,
                sebagai: this.form.seller_status,
                wilayah: array,
                status: this.form.status,
              };
              let payload2 = {
                member_id: this.form.id,
                nama_gudang: "Gudang " + this.form.nama_lengkap,
                alamat: "-",
                keterangan: "-",
                jenis: 1,
              };
              this.$store
                .dispatch("member/savekonfirmasi", payload)
                .then(() => {
                  this.$store
                    .dispatch("gudang/save", [payload2])
                    .then((resp) => {
                      let idgud = resp
                      let payload = {
                        blok: "Blok " + this.form.nama_lengkap,
                        gudang_id: idgud.id
                      }
                      this.$store.dispatch('tokoblok/save', [payload])
                    })
                    .catch((e) => console.error(e));
                  this.modaldetail = false;
                  this.modalkonfirmasi = false;
                  this.displaySuccess({
                    text: "Berhasil dikonfirmasi",
                  });
                  this.getData();
                  this.resetForm();
                })
                .catch((e) => {
                  this.label = "Submit";
                  this.displayError(e);
                  return false;
                });
            } else {
              // console.log('Ter',array)
              var array = JSON.parse("[" + this.pilihteritori + "]");
              let payload = {
                member_id: this.form.id,
                // nama_cabang: this.form.nama_cabang,
                sebagai: this.form.seller_status,
                wilayah: array,
                status: this.form.status,
              };
              let payload2 = {
                member_id: this.form.id,
                nama_gudang: "Gudang " + this.form.nama_lengkap,
                alamat: "-",
                keterangan: "-",
                jenis: 1,
              };
              this.$store
                .dispatch("member/savekonfirmasi", payload)
                .then(() => {
                  this.$store
                    .dispatch("gudang/save", [payload2])
                    .then(() => { })
                    .catch((e) => console.error(e));
                  this.label = "Submit";
                  this.displaySuccess({
                    text: "Berhasil dikonfirmasi",
                  });
                  this.resetForm();
                  this.getData();
                  this.modaldetail = false;
                })
                .catch((e) => {
                  this.label = "Submit";
                  this.displayError(e);
                  return false;
                });
            }
          }
        });
      },
        async submiteditter() {
        this.$refs.formEditKonfirmasi.validate().then((success) => {
          if (success) {
            this.label = "Loading...";
            if (this.pilihteritori.length > 1) {
              let join = this.pilihteritori;
              let string = join.join(",");
              var array = JSON.parse("[" + string + "]");
              let payload = {
                member_id: this.form.id,
                // nama_cabang: this.form.nama_cabang,
                // sebagai: this.form.seller_status,
                teritori_id: array,
                aksi: "move",
                // status: this.form.status,
              };
              this.$store
                .dispatch("member/pindahmkt", payload)
                .then(() => {
                  this.modaleditter = false;
                  this.modalkonfirmasi = false;
                  this.displaySuccess({
                    text: "Berhasil dipindahkan",
                  });
                  this.getData();
                  this.resetForm();
                })
                .catch((e) => {
                  this.label = "Submit";
                  this.displayError(e);
                  return false;
                });
            } else {
              // console.log('Ter',array)
              var array = JSON.parse(this.pilihteritori);
              let payload = {
                member_id: this.form.id,
                // nama_cabang: this.form.nama_cabang,
                // sebagai: this.form.seller_status,
                teritori_id: this.pilihteritori,
                aksi: "move",
              };
              this.$store
                .dispatch("member/pindahmkt", payload)
                .then(() => {
                  this.label = "Submit";
                  this.displaySuccess({
                    text: "Berhasil dipindahkan",
                  });
                  this.resetForm();
                  this.getData();
                  this.modaleditter = false;
                })
                .catch((e) => {
                  this.label = "Submit";
                  this.displayError(e);
                  return false;
                });
            }
          }
        });
      },
        async hapuseditter() {
        this.$swal({
          title: "Anda yakin?",
          text: "Data Teritori ini akan dilepas atau dikosongkan",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((res) => {
          if (res.value) {
            // console.log('Ter',array)
            // var array = JSON.parse(this.pilihteritori);
            let payload = {
              member_id: this.form.id,
              // nama_cabang: this.form.nama_cabang,
              // sebagai: this.form.seller_status,
              teritori_id: this.form.wilayah.length > 0 ? this.form.wilayah[0].teritori_id : null,
              aksi: "unset",
            };
            this.$store
              .dispatch("member/pindahmkt", payload)
              .then(() => {
                this.label = "Submit";
                this.displaySuccess({
                  text: "Berhasil dihapus",
                });
                this.resetForm();
                this.getData();
                this.modaleditter = false;
              })
              .catch((e) => {
                this.label = "Submit";
                this.displayError(e);
                return false;
              });
          }
        });
      },
        async clearExportedFile(filename) {
        try {
          const config = {
            url: `/clear-export`,
            method: "get",
            params: {
              filename,
            },
            headers: {
              Authorization: `${localStorage.tokenType} ${localStorage.token}`,
            },
          };
          const response = await service.sendRequest(config);

          return Promise.resolve(response.data);
        } catch(e) {
          if (e.response && e.response.data) {
            const blob = e.response.data;
            const text = await blob.text();
            const errorJson = JSON.parse(text);

            return Promise.reject(errorJson);
          }
          return Promise.reject(e);
        }
      },
        async print() {
        try {
          const config = {
            url: `/excel-data-member`,
            method: "get",
            headers: {
              Authorization: `${localStorage.tokenType} ${localStorage.token}`,
            },
          };
          const response = await service.sendRequest(config);

          return Promise.resolve(response.data);
        } catch(e) {
          return this.customRejectPromise(e);
        }
      },
        async exportMember() {
        try {
          this.loading = true;

          const response = await this.print();
          await window.open(response);
          setTimeout(async () => {
        // delete selected file
        const arrFile = response.split("/");
        const filename = arrFile[arrFile.length - 1];

        await this.clearExportedFile(filename);
      }, 1000);

      this.loading = false;
    } catch(e) {
      this.loading = false;
      this.displayError(e);
      return false;
    }
  },
  onSelectedImage(e) {
    const fileObj = e.target.files || e.dataTransfer.files;
    const file = fileObj[0];
    const images_ext = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
    if (!images_ext.includes(file.type)) {
      this.displayError({
        message: "Harap pilih file foto!",
      });
      return false;
    }

    this.preview_image = URL.createObjectURL(file);
    this.form.file_foto = file;
  },
  onContext(ctx) {
    // The date formatted in the locale, or the `label-no - date - selected` string
    this.formatted = ctx.selectedFormatted;
    // The following will be an empty string until a valid date is entered
    this.selected = ctx.selectedYMD;
  },
  getJabatanName(data) {
    let find = this.jabatan_id.find((item) => item.id == data.jabatan_id);
    return find ? find.nama_jabatan : "-";
  },
  edit(item) {
    this.form = item;
    this.id = item.id;
    if (item.jabatan) {
      this.form.jabatan_id = item.jabatan.id;
    }
    this.showModal = true;
  },
  detail(item) {
    this.form = item;
    this.id = item.id;
    // if (item.akun.level) {
    //   this.form.level = item.akun.level.id;
    // }
    this.modaldetail = true;
  },
  konfirmasi(item) {
    this.form = item;
    this.id = item.id;
    this.getWilter();
    this.modalkonfirmasi = true;
  },
  konfirmasi2(item) {
    this.form = item;
    this.id = item.id;
    this.getWilter();
    this.modalkonfirmasi = true;
  },
  editeritori(item) {
    this.form = item;
    this.id = item.id;
    this.getWilter();
    this.modaleditter = true;
  },
  setbiaya(item) {
    this.formbiaya = item;
    this.getDataSetBiaya(item)
    // this.id = item.id;
    // this.getWilter();
    this.modalbiaya = true;
  },
  setizin(item) {
    this.formizin = item;
    // this.getDataSetIzin(item)
    // this.id = item.id;
    // this.getWilter();
    this.modalizin = true;
  },
  add() {
    this.resetForm();
    this.showModal = true;
  },
  remove(item) {
    this.$swal({
      title: "Anda yakin?",
      text: "Data Member ini akan dihapus",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Batal",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ml-1",
      },
      buttonsStyling: false,
    }).then((res) => {
      if (res.value) {
        const fd = new FormData();
        fd.append("data[0][id]", item.id);
        fd.append("data[0][fungsi]", 2);
        this.$store
          .dispatch("member/save", fd)
          .then(() => this.getData())
          .catch((e) => {
            this.displayError(e);
            return false;
          });
      }
    });
  },
  resetForm() {
    this.id = null;
    this.form = {
      nama_lengkap: null,
      no_hp: null,
      email: null,
      nik: null,
      nip: null,
      alamat: null,
      jabatan_id: null,
      jk: null, //Di isi dengan 1 = Laki-Laki OR 2 = Perempuan
      agama: null, //Di isi dengan 1 = ISLAM OR 2 = KRISTEN OR 3 = KATOLIK OR 4 = HINDU OR 5 = BUDDHA OR 6 = KHONGHUCU
      status_perkawinan: null, // Di isi dengan 1 = Lajang OR 2 = Menikah OR 3 = Duda/Janda
      status_Member: null, //Di isi dengan 1 = Aktif Or 0 = Tidak Aktif
      status_sp: null, // Di isi dengan 0 = Tidak Mempunyai Sp Or 1 = Sp 1 Or 2 = Sp 2 Or 3 = Sp 3
      kampus_sekolah: "",
      pendidikan_terakhir: "",
      kualifikasi_pendidikan: "",
      masa_kerja: "",
      grade_Member: "",
      file_foto: "",
      tempat_lahir: "",
      tanggal_lahir: "", //Format 2022-01-22
    };
  },
  submit() {
    this.$refs.formMember.validate().then((success) => {
      if (success) {
        this.label = "Loading...";
        const fd = new FormData();
        fd.append(`data[0][nama_lengkap]`, this.form.nama_lengkap);
        fd.append(`data[0][no_hp]`, this.form.no_hp);
        fd.append(`data[0][email]`, this.form.email);
        fd.append(`data[0][nik]`, this.form.nik);
        fd.append(`data[0][nip]`, this.form.nip);
        fd.append(`data[0][alamat]`, this.form.alamat);
        fd.append(`data[0][jabatan_id]`, this.form.jabatan_id);
        fd.append(`data[0][jk]`, this.form.jk);
        fd.append(`data[0][agama]`, this.form.agama);
        fd.append(`data[0][status_perkawinan]`, this.form.status_perkawinan);
        fd.append(`data[0][status_Member]`, this.form.status_Member);
        fd.append(`data[0][status_sp]`, this.form.status_sp);
        fd.append(`data[0][kampus_sekolah]`, this.form.kampus_sekolah);
        fd.append(`data[0][pendidikan_terakhir]`, this.form.pendidikan_terakhir);
        fd.append(`data[0][kualifikasi_pendidikan]`, this.form.kualifikasi_pendidikan);
        fd.append(`data[0][masa_kerja]`, this.form.masa_kerja);
        fd.append(`data[0][grade_Member]`, this.form.grade_Member);
        fd.append(`data[0][tempat_lahir]`, this.form.tempat_lahir);
        fd.append(`data[0][tanggal_lahir]`, this.form.tanggal_lahir);

        if (this.id) {
          fd.append(`data[0][id]`, this.id);
        }

        if (this.form.file_foto) {
          fd.append("file_foto", this.form.file_foto);
        }

        this.$store
          .dispatch("member/save", fd)
          .then(() => {
            this.preview_image = null;
            this.label = "Submit";
            this.resetForm();
            this.showModal = false;
            this.getData();
          })
          .catch((e) => {
            this.label = "Submit";
            this.displayError(e);
            return false;
          });
      }
    });
  },
  getData() {
    const perPage = parseInt(this.perPage);
    const currentPage = (parseInt(this.currentPage) - 1) * perPage;
    const payload = {
      search: this.filter != null ? this.filter : null,
      order: "desc",
      start: currentPage,
      length: this.perPage,
      // filter_by: "nama_lengkap",
      // category_paket_id: this.filterKatPaket != null ? this.filterKatPaket.id : null,
      // paket_id: this.filterPaket != null ? this.filterPaket.id : null,
      // jadwal_id: this.filterJadwal != null ? this.filterJadwal.id : null,
      sebagai: this.seller_sebagai,
    };
    this.$store.dispatch("member/getData", payload).then(() => {
      let items = JSON.parse(JSON.stringify(this.$store.state.member.datas));
      let items_total = this.$store.state.member.totals;
      this.items = items;
      this.totalRows = items_total;
      // this.items = this.$store.state.member.datas;
      // this.totalRows = this.items.length;
    });
  },
  getDataSetIzin(item) {
    const payload = {
      member_id: item.id,
    };
    this.$store.dispatch("setizinmember/getData", payload).then(() => {
      let items = JSON.parse(JSON.stringify(this.$store.state.setizinmember.datas));
      let items_total = this.$store.state.setizinmember.totals;
      this.itemizin = items;
      // this.totalRows = items_total;
      // this.items = this.$store.state.member.datas;
      // this.totalRows = this.items.length;
    });
  },
  getDataSetBiaya(item) {
    const payload = {
      member_id: item.id,
    };
    this.$store.dispatch("setbiayamember/getData", payload).then(() => {
      let items = JSON.parse(JSON.stringify(this.$store.state.setbiayamember.datas));
      let items_total = this.$store.state.setbiayamember.totals;
      this.itembiaya = items;
      // this.totalRows = items_total;
      // this.items = this.$store.state.member.datas;
      // this.totalRows = this.items.length;
    });
  },
  async getWilter() {
    let wilter = await this.$store.dispatch("member/getwilayahteritori");
    // let items = JSON.parse(JSON.stringify(this.$store.state.member.datas_wilter));
    wilter = JSON.parse(JSON.stringify(wilter));
    let newwilter = [];
    wilter.map((item) => {
      newwilter.push({
        text: item.nama_teritori + " - " + (item.nama_cabang != null ? item.nama_cabang : "Belum ada yang memiliki cabang ini"),
        value: item.id,
      });
    });
    this.optwilter = newwilter;

    // console.log('Wilter', wilter)
  },
  async getProvinsi() {
    let provinsi = await this.$store.dispatch("wilayah/getprovinsi");
    provinsi = JSON.parse(JSON.stringify(provinsi));
    let newprovinsi = [];
    provinsi.map((item) => {
      newprovinsi.push({
        text: item.name,
        value: item.id,
      });
    });
    // this.id_provinsi = newprovinsi;
    this.optprovinsi = newprovinsi;
    // });
  },
  async getkabkota() {
    const params = {
      id_provinsi: this.form.provinsi,
    };
    // this.$store.dispatch("wilayah/getkabko", params).then((response) => {
    // this.optkabko = response
    // });

    let kabko = await this.$store.dispatch("wilayah/getkabko", params);
    kabko = JSON.parse(JSON.stringify(kabko));
    let newkabko = [];
    kabko.map((item) => {
      newkabko.push({
        text: item.name,
        value: item.id,
      });
    });
    this.optkabko = newkabko;
  },
  info(item, index, button) {
    this.infoModal.title = `Row index: ${index}`;
    this.infoModal.content = JSON.stringify(item, null, 2);
    this.$root.$emit("bv::show::modal", this.infoModal.id, button);
  },
  resetInfoModal() {
    this.infoModal.title = "";
    this.infoModal.content = "";
  },
  onFiltered(filteredItems) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  },
},
};
</script>
